<template>
  <transition
    enter-from-class="-translate-y-full"
    leave-to-class="-translate-y-full"
    enter-active-class="transition-transform duration-150"
    leave-active-class="transition-transform duration-150"
  >
    <div
      v-if="store.notificationMessage"
      class="text-13 absolute left-0 top-0 z-20 flex w-full flex-row items-center justify-between px-16 py-10 font-semibold text-white"
      :class="bgClass"
    >
      <div v-if="isSimpleMessage">{{ store.notificationMessage }}</div>
      <i18n-t v-else :keypath="path" tag="div" class="whitespace-pre-line" scope="global">
        <template #link>
          <nuxt-link :to="routeName" class="cursor-pointer underline">{{ $t(linkPath) }}</nuxt-link>
        </template>
        <template v-for="(value, key) in args" :key="key" #[key]>
          <span v-text="value" />
        </template>
      </i18n-t>
      <fa-icon class="text-19 cursor-pointer" :icon="['far', 'times']" @click="store.clearNotificationBanner" />
    </div>
  </transition>
</template>

<script setup lang="ts">
const store = useNotificationBannerStore();

const isSimpleMessage = computed(() => typeof store.notificationMessage === 'string');

const path = computed(() => {
  if (typeof store.notificationMessage === 'string') return '';

  return store.notificationMessage.path;
});
const args = computed(() => {
  if (typeof store.notificationMessage === 'string' || !store.notificationMessage.args) return {};
  return store.notificationMessage.args;
});
const linkPath = computed(() =>
  !(typeof store.notificationMessage === 'string') ? store.notificationMessage.linkPath : ''
);
const routeName = computed(() => {
  if (typeof store.notificationMessage === 'string') return { name: '' };

  return typeof store.notificationMessage.routeName !== 'string'
    ? store.notificationMessage.routeName
    : { name: store.notificationMessage.routeName };
});

const bgClass = computed(() => {
  switch (store.notificationType) {
    default:
    case 'error':
      return 'bg-red-500';
    case 'success':
      return 'bg-green-500';
    case 'warning':
      return 'bg-yellow-500';
  }
});
</script>
